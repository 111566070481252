<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title text-uppercase">{{title}}</h4>
  </div>

  <div class="row">
    <div class="col-6">{{ label }}</div>
    <div class="col-6">{{ formatBalance() }}</div>
  </div>

  <cp-payment-element
    #paymentElement
    [paymentMethod]="'card'"
    [intentClientSecretType]="'paymentIntent'"
    [intentClientSecret]="intentClientSecret"
    [user]="user"
    [paymentCards]="paymentCards"
    (canSubmit)="onCanSubmitChange($event)"
  ></cp-payment-element>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
      </div>
      <div class="col-6">
        <button
          class="btn btn-block btn-outline-secondary-text-white"
          (click)="sub()"
        >
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          Paga
        </button>
      </div>
    </div>
  </div>
</div>
