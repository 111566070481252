import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { PaymentCard } from '../../../../../../shared/models/payments.model';
import { UserService } from '../../../../../../shared/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetDefaultDialog } from './set-default-dialog/set-default-dialog.component';
import { UnexpectedErrorDialogComponent } from '../../../../../../shared/features/unexpected-error-dialog/unexpected-error-dialog.component';
import { UpdateCardDialogComponent } from './update-card-dialog/update-card-dialog.component';
import { DeleteCardDialogComponent } from './delete-card-dialog/delete-card-dialog.component';
import { AddPaymentMethodDialog } from './add-payment-method-dialog/add-payment-method-dialog.component';
import {UserModel} from '../../../../../../shared/models/user.model';

@Component({
    selector: 'cp-client-payment-methods-view',
    templateUrl: './payment-methods-view.component.html',
    styleUrls: [],
})
export class PaymentMethodsViewComponent implements OnInit, OnChanges {
    @Input() private cards: PaymentCard[];
    @Input() public iban: string|null;
    @Input() private user: UserModel;
    @Output() reload: EventEmitter<void> = new EventEmitter();

    public defaultCard: PaymentCard | null | undefined;
    public otherCards: PaymentCard[];
    public cardActions: { [key: string]: any } = {};
    public expanded: boolean = false;

    public modalShown: boolean = false;
    public statusMessage: string = '';
    public statusMessageType: 'success' | 'error' | null = null;  // Successo o errore


    constructor(
        private userService: UserService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.orderCards();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.cards) {
            this.orderCards();
        }
    }

    orderCards() {
        if (this.cards) {
            this.otherCards = [];
            this.cardActions = {};
            for (const card of this.cards) {
                if (card.default) {
                    this.defaultCard = card;
                } else {
                    this.otherCards.push(card);
                }
                this.cardActions[card.id] = {
                    'Aggiorna': {
                        ngIf: () => true,
                        disabled: () => this.modalService.hasOpenModals(),
                        action: this.updateCard.bind(this),
                    },
                    'Elimina': {
                        ngIf: () => true,
                        disabled: () => this.modalService.hasOpenModals(),
                        action: this.deleteCard.bind(this),
                    }
                };
                if (['debit', 'credit'].indexOf(card.funding) !== -1) {
                    this.cardActions[card.id]['Rendi default'] = {
                        ngIf: () => !card.default,
                        disabled: () => this.modalService.hasOpenModals(),
                        action: this.setDefaultCard.bind(this),
                    };
                }
            }
        } else {
            this.defaultCard = null;
            this.otherCards = [];
        }
    }

    toggleExpand() {
        this.expanded = !this.expanded;
    }

    setDefaultCard(card: PaymentCard) {
        if (this.modalShown) return;
        this.modalShown = true;
        let ref = this.modalService.open(SetDefaultDialog, { centered: true });
        (ref.componentInstance as SetDefaultDialog).onSubmit.subscribe(() => {
            this.userService.setDefaultCard(card).subscribe(
                (x) => {
                    ref.close();
                    return x;
                },
                (ex) => {
                    ref.dismiss();
                    this.modalShown = true;
                    let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
                        centered: true,
                    });
                    ueRef.result.finally(() => {
                        this.modalShown = false;
                    });
                }
            );
        });
        ref.result
            .then(() => {
                this.reload.emit();
            })
            .catch((err) => {
                console.log('Rejected!');
            })
            .finally(() => {
                this.modalShown = false;
            });
        ref.componentInstance.card = card;
    }

    updateCard(card: PaymentCard) {
        if (this.modalShown) return;
        this.modalShown = true;
        let ref = this.modalService.open(UpdateCardDialogComponent, {
            centered: true,
        });
        let refInnerComponent = ref.componentInstance as UpdateCardDialogComponent;
        refInnerComponent.onSubmit.subscribe(
            (data: { exp_month: number; exp_year: number }) => {
                this.userService
                    .updateCard({ card_id: card.id, ...data })
                    .subscribe(
                        (x) => {
                            ref.close();
                            return x;
                        },
                        (ex) => {
                            ref.dismiss();
                            this.modalShown = true;
                            let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
                                centered: true,
                            });
                            ueRef.result.finally(() => {
                                this.modalShown = false;
                            });
                        }
                    );
            }
        );
        ref.result
            .then(() => {
                this.reload.emit();
            })
            .catch((err) => {
                console.log('Rejected!');
            })
            .finally(() => {
                this.modalShown = false;
            });
        refInnerComponent.card = card;
    }

    addPaymentMethod(type: 'sepa' | 'card') {
        if (this.modalShown) return;
        this.modalShown = true;
        let ref = this.modalService.open(AddPaymentMethodDialog, { centered: true });
        let refInnerComponent = ref.componentInstance as AddPaymentMethodDialog;
        ref.componentInstance.user = this.user;
        ref.componentInstance.type = type;

        refInnerComponent.onSubmit.subscribe(
            () => {
                ref.close();
                this.modalShown = false;
            },
            (ex: string) => {
                ref.dismiss();
                this.modalShown = true;
                let ueRef = this.modalService.open(UnexpectedErrorDialogComponent, {
                    centered: true,
                });
                ueRef.result.finally(() => {
                    this.modalShown = false;
                });
                (ueRef.componentInstance as UnexpectedErrorDialogComponent).error = ex;
            }
        );
        ref.result
            .then(() => {
                setTimeout(() => {
                    this.reload.emit();
                }, 1500);
            })
            .catch((err) => {
                console.log('Rejected!');
            })
            .finally(() => {
                this.modalShown = false;
            });
    }

    deleteCard(card: PaymentCard) {
        if (this.modalShown) return;
        this.modalShown = true;

        const confirmationRef = this.modalService.open(DeleteCardDialogComponent, { centered: true });
        confirmationRef.componentInstance.card = card;

        confirmationRef.result.then((confirmed) => {
          if (confirmed) {
            this.userService.deleteCard(card.id).subscribe(
              () => {
                this.cards = this.cards.filter(c => c.id !== card.id);
                window.location.reload();

                this.cards = [...this.cards];

                this.showStatusMessage('Carta eliminata con successo', 'success');
                this.modalShown = false;
              },
              (error) => {

                const errorMessage = error?.error?.message || 'Si è verificato un errore durante la cancellazione della carta.';
                this.showStatusMessage(errorMessage, 'error');
                this.modalShown = false;
              }
            );
          } else {
            this.modalShown = false;
          }
        }).catch(() => {
          this.modalShown = false;
        });
      }

    showStatusMessage(message: string, type: 'success' | 'error') {
        this.statusMessage = message;
        this.statusMessageType = type;

        setTimeout(() => {
          this.statusMessage = '';
          this.statusMessageType = null;
        }, 1500);
      }
}
