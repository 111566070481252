import { Injectable } from '@angular/core';
import {UserModel, UserServiceObject, SuspensionModel} from '../models/user.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AbstractAuthorizationService} from '../../core/api/services/abstract-authorization.service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap,map} from 'rxjs/operators';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Moment} from 'moment';
import {HelperService} from './helper.service';
import {CorporateStorageService} from '../../core/api/services/corporate-storage.service';
import { PaymentCard } from '../models/payments.model';
import { InvoiceModel, UnsolvedObject } from '../models/invoices.model';
import { Paginated } from '../models/paginated.model';
import { ShowersModel } from '../models/showers.model';

@Injectable()
export class UserService extends AbstractAuthorizationService {

  constructor(private http: HttpClient,
              private helper: HelperService,
              private corporateStorageService: CorporateStorageService,
              router: Router) {
    super(router);
    this.hasUserSubject = new BehaviorSubject<boolean>(false);
  }

  private dataSubject: BehaviorSubject<UserModel>;
  public hasUserSubject: BehaviorSubject<boolean>;
  private dataSubjectSubscriptions: BehaviorSubject<UserServiceObject[]>;
  private dataSubjectPaymentCards: BehaviorSubject<PaymentCard[]>;
  private dataSubjectInvoices: BehaviorSubject<InvoiceModel[]>;
  private dataSubjectOpenInvoices: BehaviorSubject<InvoiceModel[]>;
  private dataSubjectUnsolveds: BehaviorSubject<UnsolvedObject[]>;
  private dataSubjectShowers: BehaviorSubject<ShowersModel>;
  private user: UserModel;
  private userServices: UserServiceObject[];
  private paymentCards: PaymentCard[];
  private chosenCard: PaymentCard = null;
  private dataSubjectUpcomingInvoice: BehaviorSubject<InvoiceModel>;

  public subscriptionDates = {
    minDate: undefined,
    maxDate: undefined,
    currentDate: undefined,
    canChoose: true,
  };

  public userForm;
  private formCache = {};

//#region set
  setUser(user: UserModel, skipPayload = false) {
    this.user = user;

    if (!skipPayload) {
      if (!this.corporateStorageService.getRedirectTo()) {
        if (user.payload) {
          this.corporateStorageService.setEncodedPayload(user.payload);
        }
      } else if (this.corporateStorageService.getEncodedPayload() !== user.payload) {
        user.payload = this.corporateStorageService.getEncodedPayload();
        this.updatePayload().subscribe(u => console.log('Updated user\'s payload'));
      }
    }

    this.setSubscriptionDates(user);
    if (!this.dataSubject) {
      this.dataSubject = new BehaviorSubject<UserModel>(user);
      this.hasUserSubject.next(true);
    } else {
      this.dataSubject.next(user);
    }
  }

  clearPayload(){
      this.corporateStorageService.clear();
      this.user.payload = "";
      return this.updatePayload().toPromise();
  }

  setUpcomingInvoice(invoice: InvoiceModel) {
    if (!this.dataSubjectUpcomingInvoice) {
      this.dataSubjectUpcomingInvoice = new BehaviorSubject<InvoiceModel>(invoice);
    } else {
      this.dataSubjectUpcomingInvoice.next(invoice);
    }
  }

  setUserServices(userServices: UserServiceObject[]) {
    this.userServices = userServices;
    if (!this.dataSubjectSubscriptions) {
      this.dataSubjectSubscriptions = new BehaviorSubject<UserServiceObject[]>(userServices);
    } else {
      this.dataSubjectSubscriptions.next(userServices);
    }
  }

  setPaymentCards(cards: PaymentCard[]) {
    this.paymentCards = cards;
    if (!this.dataSubjectPaymentCards) {
      this.dataSubjectPaymentCards = new BehaviorSubject<PaymentCard[]>(cards);
    } else {
      this.dataSubjectPaymentCards.next(cards);
    }
  }

  setInvoices(invoices: InvoiceModel[]) {
    if (!this.dataSubjectInvoices) {
      this.dataSubjectInvoices = new BehaviorSubject<InvoiceModel[]>(invoices);
    } else {
      this.dataSubjectInvoices.next(invoices);
    }
  }

  setOpenInvoices(invoices: InvoiceModel[]) {
    if (!this.dataSubjectOpenInvoices) {
      this.dataSubjectOpenInvoices = new BehaviorSubject<InvoiceModel[]>(invoices);
    } else {
      this.dataSubjectOpenInvoices.next(invoices);
    }
  }

  setUnsolveds(unsolveds: UnsolvedObject[]) {
    if (!this.dataSubjectUnsolveds) {
      this.dataSubjectUnsolveds = new BehaviorSubject<UnsolvedObject[]>(unsolveds);
    } else {
      this.dataSubjectUnsolveds.next(unsolveds);
    }
  }

  setShowers(showers: ShowersModel) {
    if (!this.dataSubjectShowers) {
      this.dataSubjectShowers = new BehaviorSubject<ShowersModel>(showers);
    } else {
      this.dataSubjectShowers.next(showers);
    }
  }
//#endregion set

//#region subscribe
  subscribe(): Observable<UserModel> {
    if (!this.dataSubject) {
      return this.http.post<UserModel>(`${environment.apiUrl}/user/get`, {})
        .pipe(tap(res => {
            this.setUser(res);
            return res;
          },
          err => err
        ));
    } else {
      return this.dataSubject.asObservable();
    }
  }

  subscribeHasUser(): Observable<boolean> {
      return this.hasUserSubject.asObservable();
  }

  subscribeSubscriptions(): Observable<UserServiceObject[]> {
    if (!this.dataSubjectSubscriptions) {
      return this.http.post<UserServiceObject[]>(`${environment.apiUrl}/user-service/list`, {})
        .pipe(tap(res => {
            this.setUserServices(res);
            return res;
          },
          err => err
        ));
    } else {
      return this.dataSubjectSubscriptions.asObservable();
    }
  }

  subscribePaymentCards(): Observable<PaymentCard[]> {
    if (!this.dataSubjectPaymentCards) {
      return this.http.post<PaymentCard[]>(`${environment.apiUrl}/payment-cards/list`, {})
        .pipe(tap(res => {
            this.setPaymentCards(res);
            return res;
          },
          err => err
        ));
    } else {
      return this.dataSubjectPaymentCards.asObservable();
    }
  }

  subscribeInvoices(): Observable<InvoiceModel[]> {
    if (!this.dataSubjectInvoices) {
      return this.http.post<Paginated<InvoiceModel>>(`${environment.apiUrl}/invoices/list`, { get_all: true })
        .pipe(map(res => {
            this.setInvoices(res.data);
            return res.data;
          },
          err => err
        ));
    } else {
      return this.dataSubjectInvoices.asObservable();
    }
  }

  subscribeUpcomingInvoice(): Observable<InvoiceModel> {
    if (!this.dataSubjectUpcomingInvoice) {
      return this.http.post<InvoiceModel>(`${environment.apiUrl}/invoices/upcoming-invoice`, {})
        .pipe(map(res => {
            this.setUpcomingInvoice(res);
            return res;
          },
          err => err
        ));
    } else {
      return this.dataSubjectUpcomingInvoice.asObservable();
    }
  }

  subscribeOpenInvoices(): Observable<InvoiceModel[]> {
    if (!this.dataSubjectOpenInvoices) {
      return this.http.post<Paginated<InvoiceModel>>(`${environment.apiUrl}/invoices/list-open`, {})
        .pipe(map(res => {
            this.setOpenInvoices(res.data);
            return res.data;
          },
          err => err
        ));
    } else {
      return this.dataSubjectOpenInvoices.asObservable();
    }
  }

  subscribeUnsolveds(): Observable<UnsolvedObject[]> {
    if (!this.dataSubjectInvoices) {
      return this.http.post<Paginated<UnsolvedObject>>(`${environment.apiUrl}/unsolveds/list`, {})
        .pipe(map(res => {
            this.setUnsolveds(res.data);
            return res.data;
          },
          err => err
        ));
    } else {
      return this.dataSubjectUnsolveds.asObservable();
    }
  }

  subscribeShowers(): Observable<ShowersModel> {
    if (!this.dataSubjectShowers) {
      return this.http.post<{ data: ShowersModel }>(`${environment.apiUrl}/showers/get`, {})
        .pipe(map(res => {
            this.setShowers(res.data);
            return res.data;
          },
          err => err
        ));
    } else {
      return this.dataSubjectShowers.asObservable();
    }
  }
//#endregion subscribe

//#region clear
  clear() {
    if (this.dataSubject) {
      this.dataSubject.complete();
    }
    this.dataSubject = null;
    this.hasUserSubject.next(false);
    this.userForm = null;
    this.formCache = {};
    this.user = null;
    this.subscriptionDates = {
      minDate: null,
      maxDate: null,
      currentDate: null,
      canChoose: true,
    };
    this.clearPaymentCards();
    this.clearInvoices();
    this.clearSubscriptions();
    this.clearOpenInvoices();
    this.clearUnsolveds();
    this.clearUpcomingInvoice();
    this.clearShowers();
  }

  clearPaymentCards() {
    if (this.dataSubjectPaymentCards)
      this.dataSubjectPaymentCards.complete();
    this.dataSubjectPaymentCards = null;
  }

  clearInvoices() {
    if (this.dataSubjectInvoices)
      this.dataSubjectInvoices.complete();
    this.dataSubjectInvoices = null;
  }

  clearSubscriptions() {
    if (this.dataSubjectSubscriptions)
      this.dataSubjectSubscriptions.complete();
    this.dataSubjectSubscriptions = null;
  }

  clearOpenInvoices() {
    if (this.dataSubjectOpenInvoices)
      this.dataSubjectOpenInvoices.complete();
    this.dataSubjectOpenInvoices = null;
  }

  clearUnsolveds() {
    if (this.dataSubjectUnsolveds)
      this.dataSubjectUnsolveds.complete();
    this.dataSubjectUnsolveds = null;
  }

  clearUpcomingInvoice() {
    if (this.dataSubjectUpcomingInvoice)
      this.dataSubjectUpcomingInvoice.complete()
    this.dataSubjectUpcomingInvoice = null;
  }

  clearShowers() {
    if (this.dataSubjectShowers)
      this.dataSubjectShowers.complete()
    this.dataSubjectShowers = null;
  }
//#endregion  clear

//#region user
  retrieveUser() {
    this.http.post<UserModel>(`${environment.apiUrl}/user/get`, {})
      .subscribe(res => {
          this.setUser(res);
        }
      );
  }

  update(values, skipPayload = false): Observable<UserModel> {
    if (!values.cel) {
      delete values.cel;
    }
    if (!values.tel) {
      delete values.tel;
    }
    return this.http.post<UserModel>(`${environment.apiUrl}/user/update`, {
      object: values,
      delete: {},
      user_id: this.user.id,
    })
      .pipe(tap(
        response => this.setUser(response, skipPayload),
        error => console.error(error)
      ));
  }

  // tslint:disable-next-line:variable-name
  updatePrivacy(marketing_opt: boolean, profiling_opt: boolean) {
    return this.http.post<UserModel>(`${environment.apiUrl}/user/privacy`, {
      marketing_opt,
      profiling_opt
    })
      .pipe(tap(
        response => this.setUser(response),
        error => console.error(error)
      ));
  }

  updatePayload(): Observable<UserModel> {
    return this.http
      .post<UserModel>(`${environment.apiUrl}/user/update-payload`, {
        payload: this.user.payload
      })
      .pipe(tap(
          response => this.setUser(response, true),
          error => console.error(error)
      ));
  }

  retrieveUserForm() {
    return this.http.post(`${environment.apiUrl}/user/form`, {})
      .subscribe(res => {
        this.formCache = {};
        this.userForm = res;
    });
  }

  getSelect(key: string) {
    if (this.formCache[key]) {
      return this.formCache[key];
    } else if (this.userForm) {
      this.formCache[key] = Object.keys(this.userForm[key].values).reduce((acc, subkey) => {
        acc.push({
          key: subkey,
          value: this.userForm[key].values[subkey][0].text
        });
        return acc;
      }, []);

      return this.formCache[key];
    }

    return [];
  }

  isUserReady() {
    return !!this.user;
  }

  isMinor() {
    return this.user && this.user.birthday && moment().diff(moment(this.user.birthday), 'years') < 18;
  }

  hasToUpdateProfile() {
    return this.user && !this.user.future_subscription && !this.user.subscription && !this.user.last_subscription;
  }
//#endregion user

//#region invoices
  retrieveUpcomingInvoice() {
    this.http.post<InvoiceModel>(`${environment.apiUrl}/invoices/upcoming-invoice`, {})
      .subscribe(res => {
          this.setUpcomingInvoice(res);
        }
      );
  }
//#endregion invoices

//#region subscription
  private setSubscriptionDates(user: UserModel) {

    this.subscriptionDates.canChoose = !user.last_subscription;

    const date = user.last_subscription ? moment(user.last_subscription.sub_end_date) : moment();
    this.subscriptionDates.currentDate = moment(date).add(1, 'day');

    this.subscriptionDates.minDate = this.subscriptionDates.canChoose ? this.helper.getDateForPicker(moment(date).add(1, 'day')) : this.helper.getDateForPicker(this.subscriptionDates.currentDate);
    this.subscriptionDates.maxDate = this.subscriptionDates.canChoose ? this.helper.getDateForPicker(moment(date).add(7, 'day')) : this.helper.getDateForPicker(this.subscriptionDates.currentDate);
  }

  addPaymentCardForSource(source) {
    const params = {
      id: source.id,
      type: source.type,
    }
    return this.http.post(`${environment.apiUrl}/payment-cards/add`, params);
  }

  hasPendingAndConfirmedVirtualSubscription() {
    return this.user.has_pending_and_confirmed_virtual_subscription;
  }

  canSubscribe() {
    return this.user && !this.user.future_subscription && !this.isMinor() && (!this.user.subscription || this.user.subscription.sub_info.subscription_category != "flex" || !!this.user.subscription?.sub_checkout_date) && !this.hasPendingAndConfirmedVirtualSubscription();
  }

  checkout(userService: UserServiceObject) {
    const params = {
      id: userService.id,
    }
    return this.http.post(`${environment.apiUrl}/subscription/checkout`, params);
  }
//#endregion subscription

//#region suspension
  createSuspension(startDate: Moment, description: string|null|undefined, subscription: UserServiceObject, duration?: number) {
    let params =  {
      user_service_id: subscription.id,
      start_date: startDate.format('Y-M-D'),
      description: description,
    }
    if(duration){
      params['duration'] = duration;
    }
    return this.http.post<SuspensionModel>(`${environment.apiUrl}/suspensions/create`, params).pipe(tap({
      error: error => console.error(error)
    }));
  }

  deleteSuspension(suspension: SuspensionModel, subscription: UserServiceObject) {
    return this.http.post<SuspensionModel>(`${environment.apiUrl}/suspensions/delete`, {
      user_service_id: subscription.id,
      suspension_id: suspension.id
    }).pipe(tap({
      error: error => console.error(error)
    }));
  }
//#endregion suspension

//#region cards
  getCard(id: string): PaymentCard | null {
    for (const card of this.paymentCards) {
      if (card.id === id) {
        return card;
      }
    }
    return null;
  }

  setDefaultCard(card: PaymentCard) {
    return this.http.post(`${environment.apiUrl}/payment-cards/set-default`, {id: card.id})
      .pipe(tap(
        response => {
          this.clearPaymentCards();
        },
        error => {
          console.error(error);
        }
      ))
  }

  updateCard(data: { card_id: string, exp_month: number; exp_year: number; }) {
    return this.http.post(`${environment.apiUrl}/payment-cards/update`, data);
  }

  deleteCard(cardId: string) {
    return this.http.post(`${environment.apiUrl}/payment-cards/delete`, { card_id: cardId });
  }
//#endregion cards

//#region sepa
  getMaskedIban() {
    return this.http.post<{ masked_iban: string }>(`${environment.apiUrl}/payment-sepa/getiban`, {});
  }
//#endregion sepa

//#region misc
  public static getMaxBirthDate(): NgbDate {
    const date = moment().subtract(18, 'year');
    return new NgbDate(date.year(), date.month() + 1, date.date());
  }
//#endregion misc
}
