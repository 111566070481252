<div class="modal-body">
  <div>
    <button type="button" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">Conferma Eliminazione Carta</h4>
  </div>

  <p>{{ confirmationMessage }}</p>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="cancel()">Annulla</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-outline-secondary-text-white" (click)="confirmDelete()" [disabled]="loading">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          Elimina
        </button>
      </div>
    </div>
  </div>
</div>
