<cp-waiting *ngIf="loading"></cp-waiting>

<cp-unexpected-error *ngIf="error" [showHomeLink]="false"></cp-unexpected-error>

<section class="pt-4 mb-5 container" *ngIf="!loading && !error">
  <h1 class="text-secondary text-center">DASHBOARD</h1>

  <ngb-accordion
    [closeOthers]="true"
    (panelChange)="lazyFetch($event)"
    class="line-accordion"
    #accordion="ngbAccordion"
  >
    <ngb-panel *ngIf="userHasOpenPayments" [id]="sectionIds.unpayed">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> INSOLUTI </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <cp-client-unsolveds-view
                [user]="user"
                (reload)="refetchUserAndSubscriptions(); clearAllPaymentMethods(); clearAllInvoices()"
              ></cp-client-unsolveds-view>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel [id]="sectionIds.subscription">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> Il TUO PIANO </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <section class="card bg-primary border-0 rounded text-white position-relative mx-n2" *ngIf="waitingForStripeWebhooks">
                <div class="card-body p-md-3 p-lg-4">
                  <div class="mb-2">
                    <h4 class="text-center text-secondary">ATTENZIONE!</h4>
                  </div>

                  <p class="text-center">
                    La richiesta di acquisto abbonamento è stata presa in carico.
                    <br/>
                    È necessario qualche minuto affinché l'abbonamento sia visibile qui.
                    <br/>
                    Se l'abbonamento da te acquistato non dovesse comparire nel giro di un giorno, ti invitiamo a contattare
                    il servizio clienti.
                  </p>
                </div>
              </section>
              <cp-client-subscription-view
                (reload)="refetchUserAndSubscriptions()"
                [userSubscription]="userSubscription"
                [forceNoRenew]="shouldNotBeAbleToRenew"
                *ngIf="userSubscription && !waitingForStripeWebhooks"
              ></cp-client-subscription-view>
              <a *ngIf="shouldBuy" class="btn btn-block btn-secondary mt-n1 pb-1 pt-2 mx-n2 w-auto" [routerLink]="'/subscription'">Acquistane uno nuovo</a>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel [id]="sectionIds.showers">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> DOCCE </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="!areShowersLoaded" class="text-center">
          <cp-spinner></cp-spinner>
        </div>
        <div class="container" *ngIf="areShowersLoaded">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <cp-showers-view [showers]="showers" (reload)="refetchShowers(); clearAllPaymentMethods()"></cp-showers-view>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel [id]="sectionIds.cards">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> METODI DI PAGAMENTO </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="!paymentCards" class="text-center">
          <cp-spinner></cp-spinner>
        </div>
        <div class="container" *ngIf="paymentCards">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <cp-client-payment-methods-view
                [cards]="paymentCards"
                [iban]="iban"
                [user]="user"
                (reload)="refetchAllPaymentMethods()"
              ></cp-client-payment-methods-view>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel [id]="sectionIds.invoices" [style]="{overflow: 'visible'}">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> FATTURE </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="!invoices" class="text-center">
          <cp-spinner></cp-spinner>
        </div>
        <div *ngIf="invoices" class="container">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <cp-client-invoices-view [invoices]="invoices"></cp-client-invoices-view>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel [id]="sectionIds.history">
      <ng-template ngbPanelHeader let-opened="opened">
        <button
          ngbPanelToggle
          class="text-left px-0 btn-block btn btn-link h5 mb-0 border-0 text-decoration-none d-flex shadow-none"
        >
          <span class="flex-fill"> STORICO ABBONAMENTI </span>
          <i class="ci" [ngClass]="opened ? 'ci-minus-primary' : 'ci-plus-primary'"></i>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="!otherSubscriptions" class="text-center">
          <cp-spinner></cp-spinner>
        </div>
        <div *ngIf="otherSubscriptions && otherSubscriptions.length > 0" class="container">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0">
              <div class="mb-2" *ngFor="let sub of otherSubscriptions.slice().reverse()">
                <cp-client-subscription-view
                  [userSubscription]="sub"
                  [forceNoRenew]="shouldNotBeAbleToRenew"
                ></cp-client-subscription-view>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="otherSubscriptions && otherSubscriptions.length == 0" class="text-center">Niente da mostrare</div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</section>
