import { AutoLogoutService } from '../../../../services/autologout.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../../shared/services/user.service';
import { HelperService } from '../../../../../../shared/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import {
    UserServiceObject,
    UserModel,
} from '../../../../../../shared/models/user.model';
import { PaymentCard } from '../../../../../../shared/models/payments.model';
import * as moment from 'moment';
import { InvoiceModel } from '../../../../../../shared/models/invoices.model';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ShowersModel } from '../../../../../../shared/models/showers.model';
import { combineLatest } from "rxjs";

@Component({
    selector: 'cp-client-main',
    templateUrl: './home.component.html',
    styleUrls: [],
})
export class HomeComponent implements OnInit, AfterViewInit {
    @ViewChild('accordion') accordion: NgbAccordion;
    public sectionIds = {
        unpayed: 'OpenPaymentsSection',
        subscription: 'SubscriptionSection',
        showers: 'ShowersSection',
        cards: 'PaymentCardsSection',
        invoices: 'InvoicesSection',
        history: 'OtherSubscriptionsSection',
    };

    public user: UserModel;
    public waitingForStripeWebhooks = false;
    public userHasOpenPayments: boolean;
    public userSubscription: UserServiceObject;
    public shouldNotBeAbleToRenew: boolean;
    public shouldBuy: boolean
    private allSubscriptions: UserServiceObject[];
    public otherSubscriptions: UserServiceObject[];
    public paymentCards: PaymentCard[];
    public iban: string | null;
    public invoices: InvoiceModel[];
    public showers: ShowersModel;

    public loading: boolean = true;
    public error: boolean = false;

    public arePaymentMethodsLoaded: boolean = false;
    public areInvoicesLoaded: boolean = false;
    public areShowersLoaded: boolean = false;
    private onViewInit: Promise<void>
    private resolveOnViewInit: (value?: (PromiseLike<void> | void)) => void;

    constructor(
        public userService: UserService,
        private helper: HelperService,
        private route: ActivatedRoute,
        private autoLogoutService: AutoLogoutService
    ) {
        this.onViewInit  = new Promise((resolve, _) => {
            this.resolveOnViewInit = resolve;
        })
    }

    ngOnInit() {
        this.userService
            .subscribe()
            .pipe(
                map(user => {
                    return user;
                })
            )
            .subscribe(
                async user => {
                    this.user = user;
                    this.userHasOpenPayments = user.has_open_payments;
                    const sub = user.subscription || user.last_subscription;
                    if (user.has_pending_and_confirmed_virtual_subscription) {
                        this.waitingForStripeWebhooks = true
                        await this.fetchOtherSubscriptions();
                        this.loading = false;
                        this.focusOnTargetAsSoonAsPossible()
                    } else if (sub) {
                        this.userSubscription = sub;
                        await this.fetchOtherSubscriptions();
                        this.loading = false;
                        this.focusOnTargetAsSoonAsPossible()
                        this.shouldBuy = (!user.subscription || (!!user.subscription?.sub_checkout_date ?? false)) && !user.future_subscription;
                    } else {
                        if (user.trial_status.has_pending_trial)
                            this.helper.redirectTo('/free-trial');
                        else
                            this.helper.redirectTo('/subscription');
                    }
                },
                ex => {
                    this.error = true;
                    this.loading = false;
                }
        );

        this.autoLogoutService.resetIdle();
    }

    ngAfterViewInit(): void {
        this.resolveOnViewInit()
    }

    /**
     * Expandes the section specified in the url.
     * If none section is specified or section is invalid it will expand first section (OpenPayments if any or Subscription)
     */
    focusOnTargetAsSoonAsPossible() {
        this.onViewInit.then(() => {
            this.route.params.subscribe(async (params) => {
                const id = params['id'];
                const sectionName = this.sectionIds[id] || null;
                await new Promise((resolve) => setTimeout(resolve, 5));
                if (!sectionName) this.accordion.expandAll();
                else {
                    this.accordion.expand(sectionName);
                }
            });
        })
    }

    lazyFetch(event: NgbPanelChangeEvent) {
        if (event.nextState) {
            switch (event.panelId) {
                case this.sectionIds.cards:
                    this.fetchAllPaymentCardsIfNotDone();
                    break;
                case this.sectionIds.invoices:
                    this.fetchAllInvoicesIfNotDone();
                    break;
                case this.sectionIds.showers:
                    this.fetchShowersIfNotDone();
                    break;
            }
        }
    }

    async fetchOtherSubscriptions() {
        this.allSubscriptions = null;
        this.otherSubscriptions = null;
        this.allSubscriptions = await this.userService
            .subscribeSubscriptions()
            .pipe(
                map((user) => {
                    return user;
                })
            )
            .pipe(take(1))
            .toPromise();
        let others = [];
        let maybeShouldNotBeAllowedToReturn = false;
        this.allSubscriptions.forEach((us) => {
            if (us.id !== this.userSubscription?.id) {
                others.push(us);
            }
            if (moment(us.sub_start_date).startOf('day').isAfter(moment())) {
                maybeShouldNotBeAllowedToReturn = true;
            }
        });
        this.shouldNotBeAbleToRenew = maybeShouldNotBeAllowedToReturn || this.waitingForStripeWebhooks;
        this.otherSubscriptions = others;
    }

    fetchAllPaymentCardsIfNotDone() {
        if (this.arePaymentMethodsLoaded) return;
        this.fetchAllPaymentMethods();
    }

    clearAllPaymentMethods() {
        this.arePaymentMethodsLoaded = false;
        this.paymentCards = null;
        this.userService.clearPaymentCards();
    }

    refetchAllPaymentMethods() {
        this.clearAllPaymentMethods();
        this.fetchAllPaymentMethods();
    }

    fetchAllPaymentMethods() {
        this.paymentCards = null;
        combineLatest([
            this.userService.getMaskedIban().pipe(map(
                (response: any) => {
                    if (response.masked_iban) {
                        this.iban = response.masked_iban;
                    } else {
                        this.iban = null;
                    }
                })),
            this.userService
                .subscribePaymentCards()
                .pipe(map((cards) => {
                    this.paymentCards = cards;
                }))
        ]).subscribe(_ => {
            this.arePaymentMethodsLoaded = true;
        })
    }

    fetchAllInvoicesIfNotDone() {
        if (this.areInvoicesLoaded) return;
        this.fetchAllInvoices();
    }

    clearAllInvoices() {
        this.areInvoicesLoaded = false;
        this.invoices = null;
        this.userService.clearInvoices();
    }

    fetchAllInvoices() {
        this.invoices = null;

        this.userService
            .subscribeInvoices()
            .pipe(
                map((invoices) => {
                    return invoices;
                })
            )
            .subscribe((invoices) => {
                this.invoices = invoices;
                this.areInvoicesLoaded = true;
            });
    }


    fetchShowersIfNotDone() {
        if (this.areShowersLoaded) return;
        this.fetchShowers();
    }

    clearShowers() {
        this.areShowersLoaded = false;
        this.showers = null;
        this.userService.clearShowers();
    }

    refetchShowers() {
        this.clearShowers();
        this.fetchShowers();
    }

    fetchShowers() {
        this.showers = null;

        this.userService
            .subscribeShowers()
            .subscribe((showers) => {
                this.showers = showers;
                this.areShowersLoaded = true;
            });
    }


    refetchUserAndSubscriptions() {
        this.loading = true;
        this.error = false;
        this.shouldNotBeAbleToRenew = false;
        this.userHasOpenPayments = null;
        this.userSubscription = null;
        this.userService.clear();
        this.ngOnInit();
    }
}
