import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../../../../shared/services/user.service';
import { PaymentCard } from '../../../../../../../shared/models/payments.model';

@Component({
  selector: 'cp-delete-card-dialog',
  templateUrl: './delete-card-dialog.component.html',
  styleUrls: []
})
export class DeleteCardDialogComponent {

  @Input() card: PaymentCard;
  @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  public confirmationMessage: string;
  public loading: boolean = false; 

  constructor(public modal: NgbActiveModal, private userService: UserService) {}

  ngOnInit(): void {
    if (this.card) {
      this.confirmationMessage = `Sei sicuro di voler eliminare la carta ${this.card.last4}?`;
    }
  }

confirmDelete() {
  this.loading = true;  
  this.userService.deleteCard(this.card.id).subscribe(
    () => {
     
      this.onConfirm.emit();  
      this.modal.close(); 
      window.location.reload(); 
   
      this.confirmationMessage = "Carta eliminata con successo!";
      setTimeout(() => {
        this.modal.dismiss(); 
      }, 2000);  

    
    },
    (error) => {
   
      console.error('Errore durante la cancellazione della carta', error);

     
      if (error?.error?.message) {
        this.confirmationMessage = error.error.message;
      } else {
        this.confirmationMessage = "Errore durante la cancellazione della carta. Riprova più tardi.";
      }

      this.loading = false; 
    }
  );
}


  
  cancel() {
    this.onCancel.emit(); 
    this.modal.dismiss(); 
  }
}
