import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubscriptionModel } from '../models/subscription.model';
import { AbstractAuthorizationService } from '../../core/api/services/abstract-authorization.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { VirtualSubscriptionModel } from '../models/user-subscription.model';
import { map, tap } from 'rxjs/operators';
import { CorporateStorageService } from '../../core/api/services/corporate-storage.service';
import { GymModel } from '../models/gym.model';
import { ListInterface } from '../models/type.model';
import { SubscriptionPriceService } from './subscription-price.service';

@Injectable()
export class SubscriptionsService extends AbstractAuthorizationService {
    subscriptions: SubscriptionModel[] = [];
    selectableSubscriptions: SubscriptionModel[] = [];
    selected: SubscriptionModel;
    virtualSubscription: VirtualSubscriptionModel;

    constructor(
        private readonly http: HttpClient,
        private readonly corporateStorageService: CorporateStorageService,
        private readonly subscriptionPriceService: SubscriptionPriceService,
        router: Router
    ) {
        super(router);
    }

    public getSubscriptions() {
        return this.http
            .post<ListInterface<SubscriptionModel>>(`${environment.apiUrl}/subscription/list`, {})
            .pipe(map(response => response.data));
    }

    public retrieveSubscriptions() {
        return this.getSubscriptions().pipe(
            tap(allSubscriptions => {
                this.subscriptions = allSubscriptions;
                this.subscriptions.sort((subA, subB) => {
                    if (subA.is_presale !== subB.is_presale) {
                        // Put first the presale subscriptions
                        return subA.is_presale ? -1 : 1;
                    } else if (subA.is_extra_allowed !== subB.is_extra_allowed) {
                        // Put first the extra allowed subscriptions
                        return subA.is_extra_allowed ? -1 : 1;
                    } else if (!!subA.promotion !== !!subB.promotion) {
                        // Put first the subscriptions with associated promotions
                        return subA.promotion ? -1 : 1;
                    } else if (subA.interval !== subB.interval) {
                        // Put first the monthly subscriptions
                        return subA.interval === 'month' ? -1 : 1;
                    } else if (subA.interval === 'month') {
                        // For monthly subscriptions prefer credit card vs SDD
                        return subA.payment_method === subB.payment_method
                            ? 0
                            : subA.payment_method === 'card'
                            ? -1
                            : 1;
                    } else {
                        // We don't care about the order of the rest
                        return 0;
                    }
                });
                this.refreshSelectableSubscriptions();
            })
        );
    }

    clear() {
        this.subscriptions = [];
        this.selected = undefined;
    }

    createVirtualSubscription(values: any): Observable<VirtualSubscriptionModel> {
        return this.http
            .post<VirtualSubscriptionModel>(`${environment.apiUrl}/subscription/create`, {
                gyms_id: [values.gym_id],
                subscription_id: this.selected.id,
            })
            .pipe(
                tap(
                    response => {
                        this.virtualSubscription = response;
                        return response;
                    },
                    error => error
                )
            );
    }

    retrieveVirtualSubscription(id: any): Observable<VirtualSubscriptionModel> {
        return this.http.post<VirtualSubscriptionModel>(`${environment.apiUrl}/subscription/virtual`, { id }).pipe(
            tap(
                response => {
                    this.virtualSubscription = response;
                    return response;
                },
                error => error
            )
        );
    }

    refreshSelectableSubscriptions(gym?: GymModel) {
        this.selectableSubscriptions = gym
            ? this.subscriptions.filter(it => (it.gyms_id ?? []).includes(gym.id))
            : this.subscriptions;
    }

    registerIntentConfirmation(virtualSubscriptionId: string, intentId: string): Observable<any> {
        return this.http.post<void>(`${environment.apiUrl}/virtual-subscription/register-intent-confirmation`, {
            virtual_subscription_id: virtualSubscriptionId,
            intent_id: intentId,
        });
    }

    createCardSetupIntent(): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/payment-cards/create-card-setup-intent`, {});
    }

    public createSepaSetupIntent(): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/payment-sepa/create-sepa-setup-intent`, {});
    }

}
