<div class="modal-body">
  <div>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="modal-title">
      <ng-container *ngIf="type=='card'">AGGIUNGI CARTA</ng-container>
      <ng-container *ngIf="type=='sepa'">IMPOSTA IBAN</ng-container>
    </h4>
  </div>

  <cp-payment-element
    #paymentElement
    [user]="user"
    [paymentCards]="[]"
    [paymentMethod]="type"
    [intentClientSecretType]="'setupIntent'"
    [intentClientSecret]="setupIntentClientSecret"
    (canSubmit)="onCanSubmitChange($event)"
  ></cp-payment-element>

  <div class="mt-3">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-outline-light" (click)="modal.dismiss()">Annulla</button>
      </div>
      <div class="col-6">
        <button
          class="btn btn-block btn-outline-secondary-text-white"
          (click)="sub()"
        >
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          <ng-container *ngIf="type=='card'">Aggiungi</ng-container>
          <ng-container *ngIf="type=='sepa'">Imposta</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
