<!-- Carte di credito -->
<section class="card bg-primary border-0 rounded text-white mx-n2">
  <div class="card-body p-md-3 p-lg-4">
    <div class="mb-2">
      <div ngbDropdown class="float-right" [placement]="['bottom-right', 'top-right']">
        <button
          ngbDropdownToggle
          class="btn btn-link btn-sm line-height-1 mr-n2 mt-n2 py-1 px-0 text-body no-after btn-icon btn-icon-white-shadowed"
          id="cardsDropDown"
        >
          <i class="ci ci-ellipsis-vertical-white"></i>
        </button>

        <div ngbDropdownMenu aria-labelledby="cardsDropDown">
          <button ngbDropdownItem [disabled]="modalShown" (click)="addPaymentMethod('card')">
            Aggiungi
          </button>
        </div>
      </div>

      <h4 class="text-center text-secondary">CARTE SALVATE</h4>
    </div>

    <div *ngIf="statusMessage" class="alert" [ngClass]="statusMessageType === 'success' ? 'alert-success' : 'alert-danger'">
      {{ statusMessage }}
    </div>

    <div>
      <cp-payment-card-component
        *ngIf="defaultCard"
        [card]="defaultCard"
        [actions]="cardActions[defaultCard.id] | keyvalue"
        [allowActions]="true"
      ></cp-payment-card-component>
      <p *ngIf="!defaultCard">Nessuna carta di default</p>
    </div>

    <div *ngIf="expanded && otherCards?.length > 0" id="CardsCollapse">
      <div *ngFor="let card of otherCards" class="pt-2">
        <cp-payment-card-component
          [card]="card"
          [actions]="cardActions[card.id] | keyvalue"
          [allowActions]="true"
        ></cp-payment-card-component>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-lg-3 mt-2" *ngIf="otherCards?.length > 0">
      <button
        type="button"
        (click)="toggleExpand()"
        class="btn btn-icon-outline-secondary"
        [attr.aria-expanded]="expanded"
        aria-controls="CardsCollapse"
      >
        <i class="ci" [ngClass]="expanded ? 'ci-minus-white' : 'ci-plus-white'"></i>
      </button>
    </div>
  </div>
</section>

<hr>

<!-- IBAN -->
<section *ngIf="iban" class="card bg-primary border-0 rounded text-white mx-n2">
  <div class="card-body p-md-3 p-lg-4">
    <div class="mb-2">
      <div ngbDropdown class="float-right" [placement]="['bottom-right', 'top-right']">
        <button
          ngbDropdownToggle
          class="btn btn-link btn-sm line-height-1 mr-n2 mt-n2 py-1 px-0 text-body no-after btn-icon btn-icon-white-shadowed"
          id="ibanDropDown"
        >
          <i class="ci ci-ellipsis-vertical-white"></i>
        </button>

        <div ngbDropdownMenu aria-labelledby="ibanDropDown">
          <button ngbDropdownItem [disabled]="modalShown" (click)="addPaymentMethod('sepa')">
            Sostituisci iban
          </button>
        </div>
      </div>

      <h4 class="text-center text-secondary">IBAN - Coordinate Bancarie</h4>

      <p class="card-text">
        IBAN Predefinito:
        <span class="ml-1">{{ iban }}</span>
      </p>
    </div>
  </div>

</section>
